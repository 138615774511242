/*!
 *
 *  Web Starter Kit
 *  Copyright 2015 Google Inc. All rights reserved.
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *    https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License
 *
 */
/* eslint-env browser */
(function() {
  'use strict';

  // Check to make sure service workers are supported in the current browser,
  // and that the current page is accessed from a secure origin. Using a
  // service worker from an insecure origin will trigger JS console errors. See
  // http://www.chromium.org/Home/chromium-security/prefer-secure-origins-for-powerful-new-features
  var isLocalhost = Boolean(window.location.hostname === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
    );

  if ('serviceWorker' in navigator &&
      (window.location.protocol === 'https:' || isLocalhost)) {
    navigator.serviceWorker.register('service-worker.js')
    .then(function(registration) {
      // Check to see if there's an updated version of service-worker.js with
      // new files to cache:
      // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-registration-update-method
      if (typeof registration.update === 'function') {
        registration.update();
      }

      // updatefound is fired if service-worker.js changes.
      registration.onupdatefound = function() {
        // updatefound is also fired the very first time the SW is installed,
        // and there's no need to prompt for a reload at that point.
        // So check here to see if the page is already controlled,
        // i.e. whether there's an existing service worker.
        if (navigator.serviceWorker.controller) {
          // The updatefound event implies that registration.installing is set:
          // https://slightlyoff.github.io/ServiceWorker/spec/service_worker/index.html#service-worker-container-updatefound-event
          var installingWorker = registration.installing;

          installingWorker.onstatechange = function() {
            switch (installingWorker.state) {
              case 'installed':
                // At this point, the old content will have been purged and the
                // fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in the page's interface.
                break;

              case 'redundant':
                throw new Error('The installing ' +
                                'service worker became redundant.');

              default:
                // Ignore
            }
          };
        }
      };
    }).catch(function(e) {
      console.error('Error during service worker registration:', e);
    });
  }
  // Your custom JavaScript goes here

  // Replace all SVG images with inline SVG
  jQuery('img.svg').each(function(){
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function(data) {
      // Get the SVG tag, ignore the rest
      var $svg = jQuery(data).find('svg');

      // Add replaced image's ID to the new SVG
      if(typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      // Add replaced image's classes to the new SVG
      if(typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass+' replaced-svg');
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr('xmlns:a');

      // Replace image with new SVG
      $img.replaceWith($svg);

    }, 'xml');

  });


  //selectpicker
  $('select.select').each(function(){
    $(this).selectpicker({
      style: 'select-control'
    });
  });


  //toggle menu
  $('.icon-menu').click(function () {
    $('body').toggleClass('menu-open');
  });

  $('.form-search').each(function(){
    var icon = $(this).find('.icon-search');

    icon.click(function(){
      $(this).closest('.form-search').toggleClass('open');
    });
  });



  //setheight
  function setheight() {
    var windowH = $(window).height();

    if($(window).width() > 1400){
      $('.setheight').css({
        'height': 1024
      });
    }else{
      $('.setheight').css({
        'height': windowH
      });
    }
  }
  setheight();


  //match height
  $(function() {
    $('.item').find('h4').matchHeight();
  });
  $(function() {
    $('.item').find('p').matchHeight();
  });
  $(function() {
    $('.same-h').find('.set-h').matchHeight({
      byRow: false
    });
  });


  //slick
  $('.sliders').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  });

  $('.projects-sliders').slick({
    arrows: true,
    dots: true,
    autoplay: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
  });

  $('.sliders-3').slick({
    arrows: true,
    dots: false,
    autoplay: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  function runSlider() {
    if ($(window).width() < 700 ) {
      $('.sliders-mobile').each(function(){
        var isAuto = $(this).hasClass('autoWidth');
        $(this).slick({
          arrows: false,
          dots: !isAuto,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          variableWidth: isAuto,
          centerMode: isAuto
        });
      })
      $('.smnd').slick({
        arrows: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
      });
    }else{
      if($('.sliders-mobile').hasClass('slick-sliders')){
        $('.sliders-mobile').slick('unslick');
      }
      
    }
  }
  runSlider();

  function menuMob() {
    if($(window).width() < 1000) {
      $('.has-sub').find('a').click(function(e){
        e.preventDefault();
      })
    }
  }menuMob();


  //window resize
  $(window).resize(function() {
    setheight();
    runSlider();
  });


  $(window).scroll(function(){
    var sticky = $('header'),
        scroll = $(window).scrollTop();
    
    jQuery('.ma').removeClass('in');
    $("header .main-menu li.has-child").removeClass('in');
    if (scroll >= 58) sticky.addClass('fixed');
    else sticky.removeClass('fixed');
  });


//smooth scroll
  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything
  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
      && 
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate({
          scrollTop: target.offset().top - 46
        }, 1000, function() {
          // Callback after animation
          // Must change focus!
          var $target = $(target);
          $target.focus();
          if ($target.is(":focus")) { // Checking if the target was focused
            return false;
          } else {
            $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
            $target.focus(); // Set focus again
          };
        });
      }
    }
  });

//loadmore
$('.products.load').each(function(){
  var t = $(this);

  $(".col-sm-4").slice(0, 12).show();

    if ($(".col-sm-4:hidden").length == 0) {
      $("#loadMore").hide('slow');
    }
    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".col-sm-4:hidden").slice(0, 12).slideDown();
        if ($(".col-sm-4:hidden").length == 0) {
            $("#loadMore").hide('slow');
        }
        $('html,body').animate({
            scrollTop: $(this).offset().top - 140
        }, 1000);
    });

})

$('.blog.load').each(function(){
  var t = $(this);

  $(".col-sm-6").slice(0, 8).show();

    if ($(".col-sm-6:hidden").length == 0) {
      $("#loadMore").hide('slow');
    }
    $("#loadMore").on('click', function (e) {
        e.preventDefault();
        $(".col-sm-6:hidden").slice(0, 8).slideDown();
        if ($(".col-sm-6:hidden").length == 0) {
            $("#loadMore").hide('slow');
        }
        $('html,body').animate({
            scrollTop: $(this).offset().top - 140
        }, 1000);
    });

})

$('.own-design .form').find('.form-group').each(function(){
  var t = $(this),
      label = t.find('label');

  label.click(function(){
    t.toggleClass('open');
  })
})
$('.own-design .data').each(function(){
  var t = $(this),
      label = t.find('label.visible-xs');

  label.click(function(){
    t.toggleClass('open');
  })
})

//animation
$(window).scroll( function(){
  $('.ani-scr').each( function(i){
    var bottom_of_object = $(this).offset().top + $(this).outerHeight() / 4;
    var bottom_of_window = $(window).scrollTop() + $(window).height();
    /* If the object is completely visible in the window, fade it it */
    if( bottom_of_window > bottom_of_object ){
      $(this).addClass('show');
    }
  });
  $('.dot').each( function(i){
    var bottom_of_object = $(this).offset().top + $(this).outerHeight();
    var bottom_of_window = $(window).scrollTop() + $(window).height();
    /* If the object is completely visible in the window, fade it it */
    if( bottom_of_window > bottom_of_object ){
      $(this).removeClass('base');
    }
  });
});

$(window).load(function() {
  $('.ani-onl').addClass('show');
  $('.ani-scr').each( function(i){
    var bottom_of_object = $(this).offset().top + $(this).outerHeight();
    var bottom_of_window = $(window).scrollTop() + $(window).height();
    /* If the object is completely visible in the window, fade it it */
    if( bottom_of_window > bottom_of_object ){
      $(this).addClass('show');
    }
  });
});

$(':input[type="number"]').keydown(function(e) {
  if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
    (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
    (e.keyCode >= 35 && e.keyCode <= 40)) {
    return;
  }
  if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
    e.preventDefault();
  }
});
$('.remove').each(function(){
  var t = $(this),
      item = t.closest('.item');

  t.click(function(){
    item.remove();
  })
})

$('.socmed ul li a').attr('target','_blank');
//IMAGESLOADED
  $('body').imagesLoaded()
    .done(function(){
      $('#preload').fadeOut('slow');
    })
    .fail( function() {
      $('#preload').fadeOut('slow');
    })


$(document).ready(function(){
  //Own Design
  $('.own-design').each(function(){
    var option = $('[data-toggle="own-design"]'),
        imgwrapper = $('.img-own-design');

    
    function changeAttr(){
      option.each(function(){
        var t = $(this),
            target= $('.'+t.data('name')),
            src = t.data('src'),
            isCheck = t.is(":checked");

        if(isCheck){
          var img = target.find('img').eq(0),
              imgs = $('<img src="'+src+'" class="hidden">');
          if(src!=""){
            $('body').append(imgs);
            imgwrapper.addClass('loading');
            imgs.load(function(){
              imgwrapper.removeClass('loading');
              img.attr('src',src);
              imgs.remove();
            })
          }else{
            img.attr('src','');
          }
          
        }



      })
    }
    changeAttr();
    option.change(changeAttr);
  })

  /*var canvas = document.getElementById('own');
  var context = canvas.getContext('2d');
  var img1 = new Image();
  var img2 = new Image();
  img1.src = 'images/own-design/01/Manchester-II-(rv).jpg';
  img1.onload = function() {
      canvas.width = img1.width;
      canvas.height = img1.height;
      img2.src = 'images/own-design/01/arm-01.png';
      context.globalAlpha = 1.0;
      context.drawImage(img1, 0, 0);
      context.drawImage(img2, 0, 0);
  };
  /*img2.onload = function() {
      context.globalAlpha = 1.0;
      context.drawImage(img1, 0, 0);
      context.drawImage(img2, 0, 0);
  };*/        

  
})
})();
